.contact-details{
    border-radius: 1rem;
    box-shadow: 0 8px 16px 0 rgba(50, 50, 71, 0.06), 0 8px 8px 0 rgba(50, 50, 71, 0.08);
    padding: 0;
}

.contact-details > ion-item{
    --padding-start: 0.5rem;
    --padding-end: 0.25rem;
}

.contact-details > ion-item > ion-label {
    white-space: break-spaces !important;
}

.contact-details > ion-item > ion-label p,
.contact-details > ion-item > ion-label b{
    font-size: 0.8rem;
    margin-bottom: 0;
}

.contact-icon {
    background: var(--ion-color-secondary);
    border-radius: 50%;

    margin: 0.75rem 0.75rem 0.75rem 0;
    margin-inline-end: 0.75rem;

    padding: 0.75rem;

    height: 1.5rem;
    width: 1.5rem;
}

.contact-icon-color {
    color: var(--ion-text-color);
}