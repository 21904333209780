.page--login ion-row{
    height: 100%;
}
.page--login ion-row > ion-col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.page--login ion-row > ion-col:last-child{
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(244, 91, 161, 0.76) -13.88%, rgba(250, 209, 71, 0.76) 117.54%);;
}
.page--login ion-row > ion-col:last-child > ion-img:last-child{
    width: 130%;
}

.page--login .logo{
    padding-left: var(--ion-padding, 16px);
    width: 6rem;
}
