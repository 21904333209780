.main-header::after{
    background-image: none;
}

.main-header{
    box-shadow: 0 0.1rem 0 rgba(0, 0, 0, 0.1);
}

.main-header > ion-toolbar > ion-title {
    text-align: left;
}

.main-header > ion-toolbar > div {
    width: min(100%, 20rem);
}

.main-header ion-searchbar {
    padding-top: 0;
    padding-bottom: 0;
}