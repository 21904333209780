.statistic-card {
    border-radius: 1rem;
    box-shadow: 0 8px 16px 0 rgba(50, 50, 71, 0.06), 0 8px 8px 0 rgba(50, 50, 71, 0.08);
    margin: 0;
}

.statistic-card ion-card-header ion-card-title{
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    white-space: nowrap;
}
.statistic-card ion-card-content{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.statistic-card ion-card-content ion-text {
    font-size: 2.5rem;
    font-weight: bold;

}
.statistic-card ion-card-content ion-chip {
    font-size: 0.75rem;
    background-color: var(--ion-color-secondary);
    margin-bottom: 0.5rem;
}

.trending-up {
    color: var(--ion-color-success);
}

.trending-down {
    color: var(--ion-color-danger);
}

.stat-icon {
    margin: 0 8px 0 0;
    height: 1rem;
    width: 1rem;
}