.tipping-result {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  --background: var(--ion-color-dark-contrast)
}

.tipping-result-footer-content p {
  text-align: center;
}

.icon {
  width: 4.25rem;
  height: 5rem;
  margin: 0 auto;
  display: flex;
  opacity: 40%;
}

.tipping-result--loading {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
}