.content--tipping {
    --background: var(--ion-color-dark-contrast);
}

.tipping-loader.loading, .tipping-loader.loaded{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999999999;
    width: 100%;
    height: 100%;
    /*background: white;*/
    background: linear-gradient(142.65deg, #F45BA1 0.56%, #FAD147 113.96%);

}

.tipping-loader.loaded{
    opacity: 1;
    animation: slideUpAnimation 0.75s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    -webkit-animation: slideUpAnimation 0.75s ease-in-out;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
}

@keyframes slideUpAnimation {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.tipping-loader--background {
    /*background: linear-gradient(142.65deg, #F45BA1 0.56%, #FAD147 113.96%);*/
    height: 100%;
    width: 100%;
    animation: background_to_icon 1s ease-in-out 0.5s forwards;

    position: absolute;
    z-index: 999999;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@keyframes background_to_icon {
    0% {
        height: 100%;
        width: 100%;
    }
    100% {
        height: 120px;
        width: 120px;
        border-radius: 15%;
    }
}


.tipping-loader--logo {
    width: 84px;
    height: 49px;
    position: absolute;
    z-index: 999999;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
    animation: logo 1s ease-in-out .5s forwards;
}

@keyframes logo {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
}
