.employee-item{
}
.employee-avatar{
    width: 3.75rem;
    height: 3.75rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.employee-info > h3{
    font-size: 1rem;
    font-weight: bold !important;
}
.employee-info > p{
    font-size: 0.75rem;
/*font-weight: bolder !important;*/
}


