ion-card.qrcode-wrapper {
  --border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

ion-card.qrcode-wrapper ion-card-header {
  padding: 0rem;
}
ion-card.qrcode-wrapper ion-card-content {
  cursor: pointer;
  display: flex;
  color: black;
  gap: 1ch;
}

ion-card.qrcode-wrapper .path-img {
  width: 2rem;
}

ion-card.qrcode-wrapper ion-chip {
  pointer-events: none;
}

.qrcode-element {
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1;
}
.qrcode-element canvas{
  width: 100% !important;
  height: unset !important;
  aspect-ratio: 1;
}
