.brand-card{
    height: 100%;
}
.brand-card ion-card-title{
    font-size: 1rem;
    text-align: center;
    font-weight: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.brand-card ion-img{
    height: 10rem;
    padding: 1rem;
}

.brand-card ion-card-content{
    text-align: center;
}