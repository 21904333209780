.button--wide {
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
    --padding-start: 1.5rem;
    --padding-end: 1.5rem;
    font-size: 1.25rem;
}

.button--wide ion-icon:last-child {
    font-size: 1.75rem;
    margin-left: auto;
    display: flex;
}

