
.enterprise-form-step > ion-list-header {
    /*! justify-content: center; */
    /*! align-content: center; */
    align-items: center;
}
.enterprise-form-step > ion-list-header > *{
    margin-top: 0;
}

.enterprise-form-step > ion-list-header  ion-button {
    --padding-start: 0;
}



.enterprise-form-step ion-item-group {
    display: flex;
}
.enterprise-form-step ion-item-group > ion-item {
    flex: 1;
}
