.card-modal-wrapper {
	--border-radius: 2rem
}

.bank-card-modal {
	--main-bg-color: #eaebeb;
	--background:    var(--main-bg-color);
	--border-radius: 2rem
}

.bank-card-modal ion-item {
	--ion-item-background: var(--main-bg-color);
}

.card-modal-header {
	--ion-item-background: var(--main-bg-color);
}


.card-modal-header::part(native) {
	padding-left:        0;
	padding-right:        0;
	align-items:         center;
	--padding-end:       0;
	--inner-padding-end: 0;
}

.card-modal-header .close-button {
	display:         flex;
	justify-content: center;
	align-items:     center;
	height:          100%;
	margin-right: 0;
}

.card-modal-header .close-button::part(native) {
	background-color:   #b8bfc6;
	width:              2rem;
	height:             2rem;
	border-radius:      50%;
	--background-hover: gray;
}

.card-modal-section {
	border-radius: 0.75rem;
	margin-left: 0;
	margin-right: 0;
}

.payment-form-button::part(native) {
	margin:      0 auto;
	padding:     0.25rem 4.75rem;
	font-size:   1.5rem;
	font-weight: bold;
}

.payment-form-button:hover {
	--background-hover: black;
}


.card-modal-header > div {
	display:     flex;
	gap:         5px;
	align-items: center;
	font-size:   2rem;
	line-height: 1;
}

.wpwl-group.wpwl-group-submit.wpwl-clearfix {
	margin: 0;
}

/*payment system styles*/
.cnpBillingCheckoutWrapper {
	position: relative;
}

.cnpBillingCheckoutHeader {
	width:         100%;
	border-bottom: 1px solid #c0c0c0;
	margin-bottom: 10px;
}

.cnpBillingCheckoutLeft {
	width:          240px;
	margin-left:    5px;
	margin-bottom:  10px;
	border:         1px solid #c0c0c0;
	display:        inline-block;
	vertical-align: top;
	padding:        10px;
}

.cnpBillingCheckoutRight {
	width:          50%;
	margin-left:    5px;
	border:         1px solid #c0c0c0;
	display:        inline-block;
	vertical-align: top;
	padding:        10px;
}

.cnpBillingCheckoutOrange {
	font-size:   110%;
	color:       rgb(255, 60, 22);
	font-weight: bold;
}

div.wpwl-wrapper, div.wpwl-label, div.wpwl-sup-wrapper {
	width: 100%
}

div.wpwl-group-expiry, div.wpwl-group-brand {
	width: 49%;
	float: left
}

div.wpwl-group-cvv {
	width:       49%;
	float:       left;
	margin-left: 2%
}

div.wpwl-group-cardHolder, div.wpwl-sup-wrapper-street1, div.wpwl-group-expiry {
	clear: both
}

div.wpwl-sup-wrapper-street1 {
	padding-top: 1px
}

div.wpwl-wrapper-brand {
	width: auto
}

div.wpwl-sup-wrapper-state, div.wpwl-sup-wrapper-city {
	width:        32%;
	float:        left;
	margin-right: 2%
}

div.wpwl-sup-wrapper-postcode {
	width: 32%;
	float: left
}

div.wpwl-sup-wrapper-country {
	width: 66%
}

div.wpwl-wrapper-brand, div.wpwl-label-brand, div.wpwl-brand {
	display: none;
}

div.wpwl-group-cardNumber {
	width:     65%;
	float:     left;
	font-size: 20px;
}

div.wpwl-group-brand {
	width:      35%;
	float:      left;
	margin-top: 2rem
}

div.wpwl-brand-card {
	width: 65px
}

div.wpwl-brand-custom{
	margin-left: 0.5rem;
	height: 2rem
}
div.wpwl-brand-custom {
	background-size: cover;
	background-position: center;
	border-radius: 0.25rem;
	width: calc(100%/3 - 0.5rem)
}

div.wpwl-brand-MASTER {
	background-image: url("images/master.png");
}

div.wpwl-brand-VISA {
	background-image: url("images/visa.png");
}

div.wpwl-brand-AMEX {
	background-image: url("images/amex.png");
	background-position: center right;
}

.wpwl-control {
	border-radius: 8px;
	font-size:     1rem;
}

.wpwl-form {
	max-width: 100%;
	min-width: 100%;
}

.wpwl-hint {
	font-size: 0.75rem;
}

div.wpwl-label {
	font-size: 14px;
	color:     black;
}

.wpwl-wrapper > .wpwl-icon {
	top: 0.35rem
}

.wpwl-form-card {
	margin-bottom: 0;
}

.wpwl-form.wpwl-form-virtualAccount {
	margin-bottom: 0 !important;
}
.wpwl-form.wpwl-form-virtualAccount > div button {
	min-width: 100% !important;
}

.wpwl-button-pay:hover {
	background: #858688;
	border-color: unset;
}
.wpwl-button-pay {
	background: transparent;
	border: unset;
	border-radius: 0.5rem;
	width: 100%;
	color: var(--ion-color-medium);
	border: 0.1rem solid var(--ion-color-medium);

}

.wpwl-button-error[disabled], .wpwl-button-error[disabled]:hover, .wpwl-button-error[disabled]:focus, .wpwl-button-error[disabled]:active {
	color: white;
}

.wpwl-button-pay[disabled], .wpwl-button-pay[disabled]:hover, .wpwl-button-pay[disabled]:focus, .wpwl-button-pay[disabled]:active {
	background-color: var(--ion-color-medium);
	border-color: var(--ion-color-medium);
	color: white;
}


.disclaimer p{
	font-size: 0.75rem;
	text-align: center;
}

@media only screen and (max-width: 480px) {
	div.wpwl-group-brand{
		margin-top: 1.1rem;
	}

	div.wpwl-group-cardNumber {
		width:     60%;
	}

	div.wpwl-group-brand {
		width: 40%;
	}
}

