.payment-wrapper {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    margin-top: 0;
    min-height: 200px;
    position: relative;
}

.payment-wrapper > :first-child {
    margin-top: 1rem;
    border-radius: 1rem;
}

.payment-placeholder.enabled{
    background-color: var(--ion-color-light);
    box-shadow:unset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: 0.3s;
    z-index: 99999999;
}

.payment-placeholder.disabled{
    opacity: 0;
    transition: 0.1s;
    padding: 0;
}

/* Apple pay & Google Pay */
.payment-wrapper > div.wpwl-container-virtualAccount-GOOGLEPAY,
.payment-wrapper > div.wpwl-container-virtualAccount-APPLEPAY {
    background: black;
    border-radius: 0.5rem;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.payment-wrapper > div.wpwl-container-virtualAccount-GOOGLEPAY button,
.payment-wrapper > div.wpwl-container-virtualAccount-APPLEPAY button{
    margin: 0;
    width: 100%;
    height: 2.25rem;
}