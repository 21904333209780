
ion-menu ion-item {
    --border-radius: 0.75rem;
    transition: 0.35s;
}

ion-menu ion-item.selected {
    --background: var(--ion-color-light-contrast);
    margin: 0.5rem 0.5rem 0.5rem 0;
    transition: 0.5s;
}

ion-menu ion-item.selected,
ion-menu ion-item.selected ion-icon,ion-menu ion-item.selected:hover ion-label{
    color: var(--ion-color-light) !important;
}


ion-split-pane{
    --side-max-width: 15rem;
    --side-min-width: 10rem;
    --background: var(--ion-color-dark-contrast);
    padding: 0.5rem;
}
ion-menu {
    border: unset;
    --background: var(--ion-color-dark-contrast);
}

ion-menu ion-toolbar {
    --background: transparent;
    --border-width: 0 !important;
}

ion-menu ion-content {
    --background: transparent;
}

ion-menu ion-content ion-list,
ion-menu ion-content ion-list > ion-item{
    --background: transparent !important;
    background: transparent !important;
    --color: var(--ion-color-tertiary);
    font-weight: 500
}

ion-menu ion-content ion-list > ion-item:first-child > ion-avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

ion-menu ion-img{
    max-width: 8rem;
}

ion-menu ion-title{
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--ion-color-primary);
}

.menu-item-selected{
    --color: var(--ion-color-primary);
    font-weight: bold;
}
.menu-item-selected:after{
    content: '';
    width: 0.3rem;
    height: 100%;
    background-color: var(--ion-color-dark);
    position: absolute;
    right: 0.5rem;
    border-radius: 0.5rem;
    top: 0;
}
