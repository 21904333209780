.label-section a {
    color: var(--ion-text-color);
    font-size: 0.8rem;
    font-weight: bold;
}

.label-section a {
    text-decoration: none;
}

.label-section a:hover {
    text-decoration: underline;
}

.value-section a {
    color: var(--ion-color-medium);
    font-size: 0.8rem;
    font-weight: bold;
}

.value-section a {
    text-decoration: none;
}

.value-section a:hover {
    text-decoration: underline;
}
