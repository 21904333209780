.page--brands > ion-header > ion-toolbar > div{
    display: flex;
    align-items: center;
    padding-right: 1rem;
}


.page--brands .main-header ion-toolbar:not(:first-child)::part(native) .toolbar-content {
    display: flex;
    flex-direction: row;
}

.page--brands ion-col {
    padding: 0.5rem;
}
.page--brands ion-col > ion-card{
    margin: 0;
}
