.receipt {
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.receipt ion-list{
    max-width: 15rem;
    width: 100%;
}

.receipt ion-list > ion-list-header {
    padding-inline-start: 0;
}

.receipt ion-list > ion-list-header > ion-label {
    font-size: 1.25rem;
}

.receipt ion-list > ion-item {
    --min-height: 0.5rem;
    --inner-border-width: 0px;
    --border-width: 0px;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
}
.receipt ion-list > ion-item > ion-label {
    margin: 0.25rem 0;
}
