@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

:root {
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    --ion-font-family: 'DM Sans', sans-serif;
}
*{
    font-family: 'DM Sans', sans-serif;
    --ion-font-family: 'DM Sans', sans-serif;
    font-weight: 500;
}

h1 {
    font-size: 2.25rem;
}

.input-label-placement-floating.sc-ion-input-md-h,
.input-label-placement-stacked.sc-ion-input-md-h {
    min-height: 3.75rem
}

ion-app{
    background: var(--ion-color-dark-contrast);
}
ion-content{
    --background: var(--ion-color-light);
}


ion-router-outlet{
    border-radius: 1.5rem !important;
}


/****************************************************************
 Section inset
/****************************************************************/

.section-inset{
    background: var(--ion-color-dark-contrast);
    border-radius: 1.5rem;
    padding: 1rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 1rem;
}


/****************************************************************
 ION-HEADER for all pages
/****************************************************************/

.page-header {
    box-shadow: none !important;
}

.page-header > ion-toolbar {
    --min-height: 7.5rem;
    --background: transparent;
}

.page-header > ion-toolbar  h1{
    font-weight: bold;
}

.page-header > ion-toolbar > div[slot="start"] ion-title h1{
    margin: 0;
}


ion-modal{
    --border-radius: 1.5rem;
}

/*Rewritten ionic stylesheet because of bad styles priority*/
.list-ios-lines-inset .item, .list-ios .item-lines-inset {
    --inner-border-width: 0 0 0.55px 0 !important;
    --border-width: 0 !important;
}

ion-list{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

ion-list[inset="true"] {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.scrollable-content {
    padding: 0 1rem;
    overflow-x: auto;
    white-space:nowrap;
}

.scrollable-content::-webkit-scrollbar {
    display: none;
}