.individual-modal {
    --max-width: 60rem;
    --width: 100%;
    transition: 0.3s;
}

.individual-modal--full{
    --border-radius: 0;
    /*--height: 100%;*/
}

.individual-modal ion-header > ion-toolbar {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    padding-left: 0.5rem !important;
    /*Needs to overwrite ionic's paddings*/
    padding-right: calc(4 / 12 * 100%) !important;
    --background: linear-gradient(90deg, rgba(244, 91, 161, 0.76) -13.88%, rgba(250, 209, 71, 0.76) 117.54%);
}

.individual-modal ion-header > ion-toolbar{
    --avatar-size: 6.5rem;
}
.individual-modal ion-header > ion-toolbar > ion-avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
}

.individual-modal ion-header > ion-toolbar > ion-title {
    font-size: 2rem;
    text-align: left;
    padding-right: 0;
    padding-left: calc(var(--avatar-size) + 1.25rem);
}
.individual-modal ion-header > ion-toolbar > ion-title > div{
    padding-right: 1rem;
    overflow-x: scroll;
    margin-top: 0.5rem;
}
.individual-modal ion-header > ion-toolbar > ion-title > div::-webkit-scrollbar {
    display: none;
}
.individual-modal ion-header > ion-toolbar > ion-title > div > ion-chip{
    margin: 0;
}
.individual-modal ion-header > ion-toolbar > ion-title > div > ion-chip+ ion-chip{
    margin-left: 1rem;
}

.individual-modal ion-header > ion-toolbar > ion-title > ion-text {
    font-size: 1rem;
    margin-left: 1ch;
}

.individual-modal ion-item{
    --padding-start: 0;
}


.individual-modal ion-content {
    /* Overwrites default offset-top from el.styles
       Shows the maximum offset that is used for QR code
    */
    --offset-top: 5rem !important;
    --background: transparent;
    z-index: 11;
}

.individual-modal ion-content ion-row:first-child .qrcode-wrapper {
    /* Moves QR code to the top */
    margin-top: -5rem;
}

.individual-modal ion-footer{
    background: var(--ion-color-medium);
}

.individual-modal ion-footer #open-qr-modal{
    margin-bottom: var(--ion-safe-area-bottom);
}

/* Once QR dismiss from Grid we need to remove padding for QR*/
@media screen and (max-width: 576px) {
    .individual-modal ion-content{
        --offset-top: 0px !important;
    }

    .individual-modal ion-header > ion-toolbar {
        padding-right: 0 !important;
    }

}