
.modal-employees-content{
    --border-radius: 2rem;
    --backgrop-opacity:0;
    --height: 85%;
    --box-shadow: 0 0 3rem rgba(0,0,0,0.05);
}

.modal-employees-content ion-header > ion-toolbar{
    padding-bottom: 1.25rem;
    position: sticky;
    top: 0;
    z-index: 2;
    --background:  var(--ion-background-color);
}
.modal-employees-content ion-header > ion-toolbar h2{
    font-size: 1.25rem;
    font-weight: bold;
}

.modal-employees-content ion-footer {
    display: flex;
    justify-content: center;
    transition: 0.3s;
    padding-bottom: 1rem;
}

.modal-employees-content ion-footer img {
    max-width: 5rem;
    opacity: 0.5;
}