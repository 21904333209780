ion-modal.modal {
    --width: fit-content;
    --height: fit-content;
    --border-radius: 0.75rem;
    --overflow: visible;
}

ion-modal.modal > div{
    overflow: visible;
}
.wrapper {
    padding: 1.25rem 1.75rem;
    max-width: 22rem
}

.modal-title {
    margin: 0.75rem 0;
    text-align: center;
}

.modal-avatar {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -70%);
    margin-top: 0.75rem;
}

.modal-table {
    margin-bottom: 0.75rem;
}

.key {
    margin-right: 0.25rem;
    font-weight: 700;
}

.value {
    padding-left: 0.25rem;

}

.value,
.key {
    font-size: 0.75rem;
    padding-bottom: 0.75rem;
}

.btn-container {
    display: flex;
    border-radius: 0 0 0.75rem 0.75rem;
    overflow: hidden;
}

ion-button.modal-button {
    --border-radius: 0;
    --background: --ion-color-primary-contrast; 
    --color: --ion-color-primary;
    --box-shadow: none;
    --border-color: none;
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
}

.modal-button {
    flex-grow: 1;
    border-radius: 0;
    margin: 0;
    height: auto;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: none;
    border-top: 1px solid var(--ion-color-light-shade);
    --background-activated: var(--ion-color-light);
}

.modal-button + .modal-button {
    border-left: 1px solid var(--ion-color-light-shade);
    max-width: 50%;
    width: 50%;
}

.modal-button-primary {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
}



@media (max-width: 768px) {
    .modal-avatar {
        left: 50%;
    }

}
