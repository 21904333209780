.employee-controller {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}
.employee-controller > :first-child{
    flex: 1;
}
.employee-controller ion-checkbox {
    align-items: center;
    margin-top: 0.5rem;
    margin-right: 1rem;
}