ion-modal.payment-modal {
	--box-shadow:    0px 0px 40px rgba(0, 0, 0, 0.15);
	--border-radius: 2rem;
}


/*#payment-button {*/
/*	position: relative;*/
/*}*/
/*#payment-button > div {*/
/*	position: absolute;*/
/*	top: 0;*/
/*	left: 0;*/
/*	right: 0;*/
/*	bottom: 0;*/
/*	opacity: 0;*/
/*}*/

/*#payment-button > div form {*/
/*	margin: 0;*/
/*	height: 100%;*/
/*}*/

/*#payment-button > div form > div {*/
/*	height: 100%;*/
/*}*/

/*#payment-button > div button {*/
/*	position: absolute;*/
/*	top: 0;*/
/*	bottom: 0;*/
/*	left: 0;*/
/*	right: 0;*/
/*}*/
