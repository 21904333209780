.tipping-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
}

.tipping-form,
.tipping-form > * + * {
    margin-top: 1.25rem;
}
.tipping-form > ion-item {
    width: 100%;
}

.amounts-scrollable {
    max-width: 100%;
    display: flex;
    overflow-x: scroll !important;
    flex-wrap: nowrap;
}
.amounts-scrollable::-webkit-scrollbar {
    display: none;
}

.input--amount{
    max-width: 13rem;
    box-shadow: 0 0 2rem rgba(0,0,0,0.1);
    --border-radius: 1.5rem;
    border-radius: var(--border-radius);
    --min-height: 3rem;
}

.input--amount::part(native){
    /*height: 3.5rem;*/
    border: 0.1rem solid var(--ion-color-tertiary);
    border-radius: var(--border-radius);
}

.input--amount:not(.item-has-focus) ion-label{
    margin-top: -1rem;
}

.input--amount.item-has-value ion-label,
.input--amount.item-has-focus ion-label{
    margin-top: 0.2rem;
    font-size: 1rem;
    /*ion-input has zIndex 2, we need to set up absolut position and zIndex 3 to */
    z-index: 3;
    position: absolute;
}
.input--amount.item-has-value ion-input,
.input--amount.item-has-focus ion-input {
    margin-top: 0.75rem;
}
.input--amount ion-input{
    text-align: center;
}

.chip--amount,
.chip--amount--selected{
    --background: var(--ion-color-dark);
    width: 3rem;
    justify-content: center;
    color: var(--ion-color-light);
}

.chip--amount--selected{
    --background: var(--ion-color-primary);
}