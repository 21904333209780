.empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    padding: 1rem;
    border: 0.1rem solid rgba(0,0,0,0.1);
    border-radius: 0.5rem;
}